import slick from 'slick-carousel'
import blazy from 'blazy'
import ScrollMagic from 'scrollmagic'
import 'babel-polyfill'
import Vue from 'vue'

// AJAX Requests
import axios from 'axios'

window.addEventListener('DOMContentLoaded', function(){
	
// Setup the AJAX
Vue.prototype.$http = axios.create({
//   baseURL: 'https://www.barenecessities.co.uk/'
	baseURL: '/'
})

// Slideshows
jQuery('.slider').slick()

// Lazy Loading
let bLazy = new blazy({
  success: function(ele) {}
})

// Setup Mobile + Desktop Menu Handling
window.mobileToggle = function() {
  document.querySelector('body').classList.toggle('mobile-open')
  document.querySelector('.mobile-slideout-menu').classList.toggle('open')
  document.querySelector('.mobile-menu-overlay').classList.toggle('open')
}

jQuery('.toggleSearchField').click(function () {
  jQuery('.mobile-nav, .mobile-nav .search').toggleClass('open')
})

jQuery('#menu-header-left > li.menu-item.menu-item-has-children, #menu-header-right > li.menu-item.menu-item-has-children').hover(function(){
  jQuery('.site-overlay, .derp-overlay').addClass('visible')
}, function() {
  jQuery('.site-overlay, .derp-overlay').removeClass('visible')
})

jQuery('.mobile-slideout-menu .menu-primary-navigation-container > ul > li.menu-item-has-children').click(function (e){
  e.stopPropagation()
  jQuery(this).toggleClass('active')
  jQuery(this).children('ul').slideToggle()
})

jQuery('.mobile-slideout-menu .sub-menu > .menu-item-has-children').click(function (e){
  e.stopPropagation()
  jQuery(this).toggleClass('active')
  jQuery(this).children('ul').slideToggle()
})




// Handle the responsive slider.
let getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

// Setup responsive images
let setImageSizes = () => {
  let imagetype = 'data-src'
  if(getWidth() < 768) {
    imagetype = 'data-src-mobile'
  }
  for(let el of document.querySelectorAll('.lazy-slide')) {
    el.style.background = "url('"+el.getAttribute(imagetype)+"')"
  }
}
setImageSizes()

window.addEventListener('resize', () => {
  setImageSizes()
})
// End Responsive slider

// Handle the Scroll up and Scroll Down of the menu
let lastScrollTop = 0;
window.addEventListener("scroll", () => {
  let st = window.pageYOffset || document.documentElement.scrollTop;

  if (st > lastScrollTop){
    // Scroll Down
    if(st > 100){
      // document.querySelector('.mobile-nav:not(.open) .upper').style.marginTop = '-44px'
      document.querySelector('.desktop-nav').style.marginTop = '-50px'
      document.querySelector('.desktop-nav').classList.add('shrink')
      document.querySelector('.desktop-nav .nav-under').style.opacity = 0
      document.querySelector('.desktop-nav .nav-under').style.pointerEvents = 'none'
    }

  } else {
    // Scroll Up
    // document.querySelector('.mobile-nav .upper').style.marginTop = '0'
    document.querySelector('.desktop-nav').style.marginTop = '0'
    document.querySelector('.desktop-nav').classList.remove('shrink')
    document.querySelector('.desktop-nav .nav-under').style.opacity = 1
    document.querySelector('.desktop-nav .nav-under').style.pointerEvents = 'initial'
  }
  lastScrollTop = st;
}, {passive: true});
// End Scroll up and Scroll Down

// Setup ScrollMagic
var controller = new ScrollMagic.Controller();

let fadeIn = document.querySelectorAll('.fadeIn')
for(let fade of fadeIn) {
  new ScrollMagic.Scene({triggerElement: fade, offset: -50 - (window.innerHeight / 3)})
    .setClassToggle(fade, 'ready')
    .addTo(controller)
}

// Icon Slider on "How to order" row.
jQuery('.product-slider').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  prevArrow: jQuery('.product-row .prev'),
  nextArrow: jQuery('.product-row .next'),
  responsive: [
    {
      breakpoint: 678,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      }
    },
  ]
})

jQuery('.category-slider').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 678,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        prevArrow: jQuery('.category-row .prev'),
        nextArrow: jQuery('.category-row .next'),
      }
    },
  ]
})

jQuery('.main-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.thumb-slider'
});

// Slider that runs on the product pages.
// Set preferred slidesToShow
var slidesToShow = 4;
var childElements = jQuery('.thumb-slider').children().length;
// Check if we can fulfill the preferred slidesToShow
if( slidesToShow > (childElements-1) ) {
  // Otherwise, make slidesToShow the number of slides - 1
  // Has to be -1 otherwise there is nothing to scroll for - all the slides would already be visible
  slidesToShow = (childElements-1);
}

jQuery(document).ready(function(){
if(jQuery('.thumb-slider').children().length > 1){
  jQuery('.thumb-slider').slick({
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    prevArrow: jQuery('.prev-arrow'),
    nextArrow: jQuery('.next-arrow'),
    arrows: true,
    vertical: true,
    asNavFor: '.main-slider',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 880,
        settings: {
          slidesToShow: jQuery('.thumb-slider').children().length,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          vertical: false,
          dots: true,
          arrows: false,
        }
      },
    ]
  });
}
})

jQuery('.mobile-menu-padding .menu > .menu-item-has-children a').click(function(e){
  e.preventDefault(e)
  jQuery('.the-mobile-menu').addClass('open')
  jQuery(this).parent().children('.sub-menu').addClass('open')
  jQuery(this).parent().children('.sub-menu').prepend('<div class="back" style="font-size: 16px; font-weight: bold; margin-bottom: 20px;"> < &nbsp; ' + jQuery(this).html() + '</div>')
  jQuery(this).parent().children('.sub-menu').append('<div class="button" style="text-align: center; margin-top: 30px; font-weight: bold;">By Brand</div>')
  jQuery('.back').click(function(){
    jQuery(this).parent().removeClass('open')
    jQuery('.sub-menu .button').remove()
    jQuery('.the-mobile-menu').removeClass('open')
    jQuery(this).remove()
  })
})

// Sliding function that's used on the FAQ + Delivery pages.
jQuery('.toggle').click(function() {

  // Open this toggle
  jQuery(this).addClass('active')
  jQuery(this).toggleClass('open')

  // Reset all the toggle images
  let icon = jQuery(this).hasClass('open') ? 'minus' : 'plus';
  jQuery(this).children('img').attr('src', '/wp-content/themes/bare/images/'+icon+'@2x.png');

  // Close all of the other toggles
  jQuery('.toggle:not(.active)').removeClass('open')
  jQuery('.toggle:not(.active)').siblings('.content').slideUp()
  jQuery('.toggle:not(.active)').children('img').attr('src', '/wp-content/themes/bare/images/plus@2x.png')

  // Stop the During and run the slide
  jQuery(this).removeClass('active')
  jQuery(this).siblings('.content').slideToggle()

})



window.updateImg = function(el) {

  var thumb_href = jQuery(el).data("source");
  var thumb_src = jQuery(el).find('img').attr('src-orig');
  if(!thumb_src){
    thumb_src = jQuery(el).find('img').attr('src');
  }

  jQuery('.woocommerce-product-gallery__wrapper .inner > .woocommerce-product-gallery__image img, .zoomImg').attr('href', thumb_href);
  jQuery('.woocommerce-product-gallery__wrapper .inner > .woocommerce-product-gallery__image img,  .zoomImg').attr('src', thumb_src);
  jQuery('.woocommerce-product-gallery__wrapper .inner > .woocommerce-product-gallery__image img,  .zoomImg').attr('srcset', thumb_src);
  jQuery('.woocommerce-product-gallery__wrapper .inner > .woocommerce-product-gallery__image img,  .zoomImg').attr('src-orig', thumb_src);
}

window.smoothScroll = function(thingy) {
  jQuery('html, body').animate({
    scrollTop: jQuery(thingy).offset().top - 100
  }, 1000);
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
if(iOS) {
  if(!getCookie('hideToggle')) {
    jQuery('.dummy-toggle').css('display', 'block')
    jQuery('.apple-link').css('display', 'block')
  }
}

var ua = navigator.userAgent.toLowerCase();
var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
if(isAndroid) {
  if(!getCookie('hideToggle')) {
    jQuery('.dummy-toggle').css('display', 'block')
    jQuery('.google-link').css('display', 'block')
  }
}

window.hideAppToggle = function (){
  setCookie('hideToggle', true, 30)
  jQuery('.dummy-toggle').css('display', 'none')
}

jQuery('.accordion').click(function() {
  jQuery(this).children('.under').slideToggle()
})

if(document.querySelector('#ajax-products')){
  let app = new Vue({
    el: '#ajax-products',
    data: {
        productMarkup: '<div style="width: 100%; text-align: center; margin: 250px 0;"><img src="/wp-content/themes/bare/images/preloader.gif"></div>',
        actualFilters: [],
        currentFilters: [],
      currentLoop: 0,
      totalToLoop: 1,
      markupReset: false,
      currentFilters: [],
      loading: false,
    },
    created () {
      this.getFilters();
      this.getProducts();
      window.addEventListener('scroll', () => {
        if (document.querySelector('#isSalePage') && (window.innerHeight + window.scrollY) >= (document.body.offsetHeight / 2)) {
          if((this.currentLoop < this.totalToLoop) && !this.loading){
            this.currentLoop++
            this.getProducts()
          }
        }
      });
    },
    methods: {
      getFilters() {
        if(document.querySelector('input[name="product_cat"]')){
          this.currentFilters.product_cat = {type: 'product_cat', value: document.querySelector('input[name="product_cat"]').getAttribute('value')}
        }

        if(document.querySelector('#isSalePage')){
          this.currentFilters.type = {type:'type', value: 'sale'}
        }

        let derpParams = {}
        for(let k in this.currentFilters) {
          derpParams[this.currentFilters[k].type] = this.currentFilters[k].value
        }

        console.log(derpParams)

        this.$http.get('/wp-json/alt/v1/product-archive-filters', {params: derpParams}).then(res => {
          this.actualFilters = res.data
          this.totalToLoop = this.actualFilters.totalToLoop
          if(document.querySelector('#isSalePage')){
            this.getProducts()
          }
        })
      },
      getProducts() {
        let params = {}
        for(let filter of document.querySelectorAll('.fixed-filters')) {
          params[filter.getAttribute('name')] = filter.getAttribute('value')
        }
        for(let filter of this.currentFilters) {
          params[filter.type] = filter.value
        }
        params.offset = this.currentLoop
        this.loading = true
        this.$http.get('/wp-json/alt/v1/products', {params: params}).then(res => {
          if(!this.markupReset) {
            this.markupReset = true
            this.currentLoop = 0
            this.productMarkup = ''
          }
          if(this.productMarkup != res.data){
		      this.productMarkup += res.data    
          }
      
          let that = this
          setTimeout(function(){
            document.querySelector('.currency_switcher .active').click()
            that.loading = false
          }, 100)
        })

      },
      scrollFunction() {

      },
      setOptionEvent(event, taxonomy) {
       	console.log(event)
       	if(event.target.value == ''){
	       	return
       	}
       	// Get the value from the select
       	let label = ''
       	for (let key in event.target){
	       	if(event.target[key] && event.target[key].innerHTML && event.target[key].value == event.target.value) {
		       	label = event.target[key].innerHTML
	       	}
       	}
       	this.currentFilters.push({
          label: label,
          value: event.target.value,
          type: taxonomy,
        })
      },
      setOption(taxonomy, name, slug) {
        this.currentFilters.push({
          label: name,
          value: slug,
          type: taxonomy,
        })
      }
    },
    watch: {
      currentFilters(oldVal, newVal) {
        this.markupReset = false
        this.getProducts()
        this.getFilters()
      }
    }
  })
}
})
